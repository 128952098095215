import React from "react";
import Header from "../header/Header.tsx";
import Footer from "../footer/Footer.tsx";
import "../../App.css";
import "./PasswordResetChange.css";
import supabase from "../../supabaseClient.js";
import { AuthError } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";

const PasswordResetEmailForm = () => {
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<AuthError | null>(null);
  const [displaySent, setDisplaySent] = React.useState<string>("");

  const nav = useNavigate()

  const sendEmail = async () => {
    if (email) {
      const baseUrl = process.env.REACT_APP_REDIRECT_BASE_URL
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: baseUrl + '/#/passwordResetUpdate',
      });

      if (error) {
        setError(error);
      }
      if (data) {
        setDisplaySent(email);
        setEmail("");
      }
    }
  };

  return (
    <div className="parent-container">
      <header className="header-container">
        <Header downloadScroll={() => nav('/')} discordScroll={() => nav('/')} />
      </header>
      <div className="form-container">
        <div className="form-header"> Please enter your email:</div>
        <div className="password-container">
          <input
            className="input"
            name="email"
            placeholder="someone@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="button-container">
          <button className="PrimaryButton" disabled={!email} onClick={sendEmail}>
            Send Email
          </button>
        </div>
        {displaySent && (
          <div className="secondary-container">
            <div className="form-header">Please check your email!</div>
            <div className="subtext">Email sent to: {displaySent}</div>
          </div>
        )}
        {error && (
          <div className="secondary-container">
            <div className="form-header">Oh no, an error occurred!</div>
            <div className="subtext">Please try again or contact support for assistance</div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PasswordResetEmailForm;
