import React, { useEffect, useRef, useState } from "react";
import "./SubSection.css";
import FadeInContainer from "../../components/FadeInContainer/FadeInContainer.tsx";
interface ISubSectionProps {
  reverse?: boolean;
  headerText: string;
  description: string;
  mediaLink: string;
  mediaType: "IMAGE" | "VIDEO";
}
const SubSection = ({ reverse = false, headerText, description, mediaLink, mediaType }: ISubSectionProps) => {
  return (
    <FadeInContainer>
      <div className={`sub-main-container ${reverse ? "reverse" : ""}`}>
        <div className="section-left-container">
          <h1 className="section-header"> {headerText}</h1>
          <p className="section-description">{description}</p>
        </div>
        <div className="section-right-container">
          <div className="media-container">
            {mediaType == "VIDEO" && (
              <iframe
                width="100%"
                height="100%"
                src={mediaLink}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
            {mediaType == "IMAGE" && <img className="section-image" src={mediaLink}></img>}
          </div>
        </div>
      </div>
    </FadeInContainer>
  );
};

export default SubSection;
