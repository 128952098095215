import React, { useEffect, useState } from "react";
import Header from "../header/Header.tsx";
import Footer from "../footer/Footer.tsx";
import "../../App.css";
import ".././password-reset-newPassword/PasswordResetChange.css";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const [error, setError] = useState<any>(null);
  const nav = useNavigate();

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash.replace("#", "&"));
    const error = params.get("error") ?? "Unknown error";
    const errorCode = params.get("error_code") ?? "Unknown code";
    const errorDescription = params.get("error_description");
    if (error) {
      setError({ error: error, errorCode: errorCode, errorDescription: errorDescription });
    }
  }, []);

  return (
    <div className="parent-container">
      <header className="header-container">
        <Header downloadScroll={() => nav("/")} discordScroll={() => nav("/")} />
      </header>
      <div>
        <div className="form-header">Oh no, an error occurred!</div>
        <div className="subtext">Please try again or contact support for assistance</div>
        {error && (
          <div>
            <br></br>
            <div className="subtext">Error: {error.error}</div>
            <div className="subtext">Description: {error.errorDescription}</div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
