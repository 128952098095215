import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AdminTool from "./routes/admin.tsx";
import PasswordResetEmail from "./routes/passwordResetEmail.tsx";
import PasswordResetUpdate from "./routes/passwordResetUpdate.tsx";
import "./App.css";
import App from "./App.tsx";
import ErrorPage from "./containers/errorPage/ErrorPage.tsx";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "admintool",
  //   element: <AdminTool />,
  // },
  {path: "passwordResetEmail",
    element: <PasswordResetEmail />
  },
  {path: "passwordResetUpdate",
    element: <PasswordResetUpdate />
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
