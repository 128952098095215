import React, { useState } from "react";
import "./Header.css";

const Header = ({ downloadScroll, discordScroll }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((current) => !current);
  };

  return (
    <div className="container">
      <div className="logo-container" onClick={downloadScroll}>
        <img src="/AlgoBytesLogoTransparent.png" alt="AlgoBytesLogo" className="logo-image" />
        <p className="logo-text">AlgoBytes</p>
      </div>

      <span className="span-links">
        <p className="nav-text" onClick={downloadScroll}>
          Home
        </p>
        <p className="nav-text" onClick={downloadScroll}>
          Download
        </p>
        <p className="nav-text" onClick={discordScroll}>
          Discord
        </p>
      </span>
      <div className={`header-link-container ${isOpen ? "open" : ""}`}>
          <p onClick={() => downloadScroll()}> About </p>
          <p onClick={() => downloadScroll()}> Download </p>
          <p onClick={() => discordScroll()}> Discord </p>
        </div>
        <div className="nav-toggle" onClick={toggleOpen}>
          <p>
            &#9776;
          </p>
        </div>
    </div>
  );
};

export default Header;
