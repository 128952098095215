import React from "react";
import "./SectionThree.css";
import FadeInContainer from "../../components/FadeInContainer/FadeInContainer.tsx";

const SectionThree = ({ onDiscordClick }) => {
  return (
    <FadeInContainer>
      <div className="section-container">
        <h1 className="header-text">Interested in joining the AlgoBytes community?</h1>
        <p className="paragraph-text">
          AlgoBytes is built by a small team of developers who are passionate in helping others learn. We strive to
          create a learning environment for everyone no matter your experience.
        </p>
        <img src="/DiscordLogo.png" alt="Discord Logo Button" className="button" onClick={() => onDiscordClick()} />
      </div>
    </FadeInContainer>
  );
};

export default SectionThree;
